<template>
    <div class="card">
        <div>
            <b-form>
                <b-row>
                    <b-col class="margin-left-20">
                        <b-form-group class="alignMent">
                            <label>Name</label>
                            <b-form-input v-model="roleName" id="v-first-name" class="mr-bottom" placeholder="Name" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>

        </div>
        <div class="mr-right-20  inline-flex ">
            <div class="width-50">
                <div class="padding-left">Permissions :</div>
                <div class="wraper-div">
                    <input v-model="searchResult" placeholder="Search" class=" mr-bottom border-none form-control input-sm flex" />
                    <ul class="list-permission scroll-div">
                        <li v-for="permission in tagsFilter" @click="selectedDtaVal(permission._id)">
                            {{permission.name}}
                        </li>
                    </ul>
                    <div>
                        <div @click="selectAll()"><label>Select All</label></div>
                        <b-button @click="addRoleData()" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                            class="mr-1">
                            Add
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="width-50">
                 <div class="th-div-wraper">
                    <input v-model="searchResultSelected" placeholder="Search" class=" mr-bottom border-none form-control input-sm flex" />
                </div>
                <div class="scroll-div">
                   <ul class="selected-uldata">
                    <li  class="loop-data" v-for="data in tagsFilterSelected">
                    {{data.name}}
                    <span @click="deleteSelectedPermission(data.id)" class="flot-right">
                        <feather-icon class="rotate" icon="Trash2Icon" size="15" />
                    </span>
                    </li>
                   </ul>
                    <div class="deselect" @click="deSelectAll()"><label>Deselect All</label></div>
                </div> 
            </div>
        </div>
    </div>



</template>

<script>
import Vue from 'vue';
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref, watchEffect } from 'vue'
import {
    BFormTextarea, BPagination, BRow, BCol, BFormGroup, BFormInput, BFormCheckboxGroup, BFormCheckbox, BForm, BButton,
    BCard
} from 'bootstrap-vue'
import { filterTags } from '@/@core/utils/filter';
import { VueAutosuggest } from "vue-autosuggest";
export default {
    data: () => ({
        roleName: '',
        selected: [],
        allPermission: [],
        newUserOptionEdit: [],
         selectedAssetEditName: '',
        tagInputProps: {
          class: "form-control",
          placeholder: "Add new",
        },
        selectedAssetEditData:[],
        autoData:[],
        searchResult: '',
        searchData: [],
        searchResultSelected: ''
    }),
    components: {
        BFormTextarea,
        BPagination,
        vSelect,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormCheckboxGroup,
        BCard,
        VueAutosuggest
    },
    directives: {
        Ripple,
    },
    props: {

    },
    watch: {
    },
    mounted() {
        this.getPermission()
    },
    computed: {
       tagsFilter: function() {
        var textSearch = this.searchResult;
        return this.allPermission.filter(function(el) {
            return el.name.toLowerCase().indexOf(textSearch.toLowerCase()) !== -1;
        });
    },
    tagsFilterSelected: function () {
    var textSearch = this.searchResultSelected;
    return this.searchData.filter(function (el) {
        return el.name.toLowerCase().indexOf(textSearch.toLowerCase()) !== -1;
    });
}
    },
    methods: {
        deleteSelectedPermission (id) {
           this.searchData = this.searchData.filter(ele=> ele.id != id)
           this.searchResultSelected = ''
        },
        selectAll () {
            this.allPermission.forEach(ele => {
                this.searchData.push({name: ele.name, id: ele._id})
            });
        },
        deSelectAll () {
            this.searchData = []
        },
        selectedDtaVal (id) {
            this.allPermission.forEach(element => {
                if(element._id == id){
                    if(!this.searchData.find((o) => o.id === id)){
                        this.searchData.push({name: element.name, id: element._id}) 
                    }
                }
            });
        },
        addRoleData() {
            const valueData = []
            this.searchData.forEach(ele=> {
                valueData.push(ele.id)
            })
            const body = {
                name: this.roleName,
                permission_ids: valueData
            }
            const options = {
                method: "POST",
                data: body,
                headers: { "content-type": "application/json" },
                url: process.env.VUE_APP_SSO_API_URL + `/roles`,
            };
            
            this.$http(options)
                .then((res) => {
                    this.$router.push({ name: "roles" })
                })
                .catch((err) => {
                    console.log(err);
                });

        },
        getPermission() {
            const options = {
                method: "GET",
                headers: { "content-type": "application/json" },
                url: process.env.VUE_APP_SSO_API_URL + `/permissions`,
            };
            this.$http(options)
                .then((res) => {
                    this.allPermission = res.data.data.data

                })
                .catch((err) => {
                    console.log(err);
                });

        },

    }

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.width-20 {
    width: 20%;
}
.alignMent {
    padding-top: 20px;
    padding-right: 20px
}
.wraper-div {
    padding: 20px;
}
.full-width {
    width: 100%;
}
.deselect {
    position: absolute;
    bottom: 0;
    top: 446px;
}
.flex {
    display: flex;
}
.padding-20{
    padding-left: 20px;
}

.mr-right {
    margin-right: 10px;
}
.selected-uldata li {
    list-style: none;
}
.selected-uldata {
    padding: 0;
}
.parantDiv {
display: flex;
    justify-content: flex-end;
    align-items: center;
    top: -37px;
    position: relative;
    margin-left: 52px;
}
.margin-left-20 {
    margin-left: 20px;
}
.border-none {
    border: none;
}
.padding-left {
    padding-left: 20px;
}
.loop-data {
    padding: 10px;
    background-color: #e9e6e6;
    border-top: 2px solid #ffff;
    // margin-right: 20px;
    border-radius: 5px;
}
.list-permission {
    padding: 0;
}
.list-permission li {
    list-style: none;
    padding: 5px;
    // border: 1px solid #cfcfcf;
    border-bottom: none;
}
.scroll-div{
    height: 250px;
    overflow: hidden;
    overflow-y: scroll;
}
.width-40 {
    width: 40%;
}
.width-50 {
    width: 50% !important;
    // border: 1px solid grey !important;
}

.inline-flex {
    display: inline-flex;
}

.mr-bottom {
    margin-bottom: 10px;
}

.marin-20 {
    margin: 20px;
}

.label-text {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 700;
}
.th-div-wraper {
    margin-top: 42px;
    // border-radius: 5px;
}
.mr-right-20 {
    margin-right: 20px;
}
// .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
// .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
//     background-color: #7367f0 !important;
//     color: #ffff
// }

.align-right {
    display: flex;
    justify-content: flex-end;
}

.text-align-right {
    text-align: right;
}
.flot-right{
    float: right;
}
</style>
